<template>
  <div class="privacy">
    <div class="title">
      <h1>隐私政策</h1>
    </div>
    <p class="bold">更新日期：2022年3月4日</p>
    <p class="bold">生效日期：2022年3月4日</p>
    <p>
      （注册公司：北京光耀世界网络技术有限公司，以下简称“我们”）作为游戏《三国吧兄弟》（以下简称“本游戏”）的运营方，
      深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。
      我们制定本“隐私政策”并特别提示：希望您在使用本游戏软件及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。
    </p>
    <p class="bold">本隐私政策将帮助您了解：</p>
    <p>
      1.
      我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。
    </p>
    <p>
      2.
      当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。
    </p>
    <p>
      3.
      如果您未登录账号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了账号，我们会根据账号信息实现信息推送。
    </p>
    <p>
      4.
      相关敏感权限均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。
      <span class="bold">特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。</span>
    </p>
    <p class="bold">本隐私政策将帮助您了解以下内容：</p>
    <p>一、我们如何收集和使用个人信息</p>
    <p>二、我们对 Cookie 和同类技术的使用</p>
    <p>三、我们如何存储个人信息</p>
    <p>四、我们如何共享、转让、公开披露个人信息</p>
    <p>五、我们如何保护个人信息的安全</p>
    <p>六、您的权利</p>
    <p>七、未成年人使用条款</p>
    <p>八、隐私政策的修订和通知</p>
    <p>九、适用范围</p>
    <p>十、联系我们</p>
    <p class="bold">一、我们如何收集和使用个人信息</p>
    <p>在您使用本游戏软件及服务的过程中，我们将根据合法、正当、必要的原则，收集信息。我们收集或您提供的信息将用于：</p>
    <p class="bold">1.保障产品的正常基础运行；</p>
    <p class="bold">2.实现各项功能和服务；</p>
    <p class="bold">3.优化、改善产品和服务；</p>
    <p class="bold">4.保障产品、服务以及用户使用安全；</p>
    <p class="bold">5.遵循法律法规与国家标准的规定。</p>
    <p class="bold">（一）我们直接收集与使用的个人信息</p>
    <p>在您使用游戏及服务过程中，我们通常情况下会在以下场景中收集和使用您的个人信息：</p>

    <p>当您注册账号时，我们会收集、使用您的账号信息（包括，账号ID、用户名、昵称、密码）、姓名、身份证号码，收集这些信息是为了帮助您完成账号注册和使用我们提供的产品和服务，并确保您的账户安全。收集、使用姓名、身份证号码是为了满足《国家新闻出版署关于防止未成年人沉迷网络游戏的通知》及《国家新闻出版署关于进一步严格管理切实防止未成年人沉迷网络游戏的通知》有关落实网络游戏用户账号实名注册的要求。若您不提供这类信息，您可能无法正常注册账号。此外，为便于您找回您的账号，在您同意的前提下，我们会收集您的电子邮箱、手机号码；若您不提供该信息，您将无法使用电子邮箱、手机号码账号找回功能，但不影响您正常使用其他功能。</p>
    <p>
      当您使用提供的产品和服务时，我们会收集、使用您的手机厂商标识、手机型号、手机系统版本、网络类型/运营商、手机网卡mac地址、手机内存及硬盘信息（总大小、剩余量）、CPU信息（包括，名称、架构、最大频率、核数）、手机屏幕分辨率、模拟器判断、是否root、粘贴板、vaid、oaid、aaid、GAid、Android ID、IMSI、MEID（标识用户）、设备序列号（标识用户）、SD卡读取（保存账号）、SIM卡信息（一键登录）、SD卡_创建目录（生成并存储玩家账号、密码）、获取SIM卡ICCID:（标识用户信息）、获取存储信息:（用户登入游戏）
      GdtAd、Glide、ExoPlayer、百度移动广告SDK、百度定位、百度网盟广告、今日头条穿山甲联盟SDK、Gromore SDK、抖音直播SDK、极光SDK、sigmobSDK、tobidSDK、热云SDK、数数SDK移动授权登录（用于推送广告以及播放广告视频内容）
      ,运行中的进程以及方向传感器。收集这些信息是为了保障您正常使用我们的服务，维护我们服务的正常运行以及保障您的账号安全，实现防沉迷功能。若您不提供这类信息，您可能无法正常使用我们的产品和服务。为了为您推荐您可能感兴趣的内容/商业广告等信息、丰富信息推荐维度，我们会向您请求开启 地理位置权限。您可以拒绝开启，拒绝开启后，您看到的广告数量将保持不变，但广告的相关度会降低。精确地理位置信息属于个人敏感信息，拒绝提供该信息不影响您正常使用本游戏的其他功能。
    </p>

    <p>当您使用新新提供的产品和服务时，我们还会收集、使用您的日志信息，包括IP地址、登陆时间、搜索查询内容、使用语言、服务故障信息、引荐网址、浏览器标识。收集这些信息是为了保障您正常使用我们的服务，维护我们服务的正常运行以及满足监管要求。若您不提供这类信息，您可能无法正常使用我们的产品和服务。为了为您推荐您可能感兴趣的内容/商业广告等信息、丰富信息推荐维度，我们会向您请求开启 地理位置权限。您可以拒绝开启，拒绝开启后，您看到的广告数量将保持不变，但广告的相关度会降低。精确地理位置信息属于个人敏感信息，拒绝提供该信息不影响您正常使用本游戏的其他功能。</p>

    <p class="bold">1.保障本游戏软件及相关服务的安全运行、运营的质量及效率</p>
    <p>
      当您使用本游戏软件及相关服务时，为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、硬件序列号、IP
      地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、传感器信息等数据。
      <span class="bold">请您了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。</span>
    </p>

    <p>
      涉及个人信息:设备标识符(Android如Android ID、IMEI、MEID、IMSI、OAID、GAID、Serial,iOS如IDFV、IDFA)、MAC地址、IP地址、位置信息(如GPS、WLAN接入点、蓝牙和基站)、设备传感器、当前应用信息(应用名、
      用版本号)
    </p>

    <p class="bold">2.注册、登录本游戏和相关服务</p>
    <p>（1）当您注册、登录本游戏及相关服务时，我们会自动获取您的手机号（sim）创建账号，收集这些信息是为了帮助您完成注册和登录。</p>
    <p>（2）您也可以使用第三方账号登录进入本游戏，您此时将授权我们获取您在第三方账号的头像、昵称及其他公开信息，并在您同意本隐私政策后将您的第三方账号与您的本游戏账号绑定，使您可以通过第三方账号直接登录并使用本产品和相关服务。</p>
    <p>（3）当您册并登录用户账号或者使用游戏产品及服务时，需要按照我们的指引完成一系列注册程序，在过程中，您需要向我们提供以下信息:账号昵称、用产头像、手机号码、电子邮箱，收集上述信息是用于标记并识别您为用户并保存您的登录信息。上述信息是您注册并登录用户账号或者使用及产品及服务核心业务的必备信息，如果您选择不提供，您可能无法使用游戏产品及服务。</p>
    <p>
      （4）我们部分游戏产品及服务支持您使用第三方平台的账号（微信账号/QQ账号）登录，如您使用第三方平台的账号登录的，我将根据您的权获该第三方账号下的相关信息（包括:第三方账号、用户名称、头像以及您授权的其他信息，具体以该第三方账号授权页面显示为准
      ，收集上述信息是用于为您提供账号登录务以及保障您的账号安全，防范安全风险，如您拒绝授权此类信息，则您将无法使用第三方平台的账号登录我们平台，但不影响我们提供的其他产品和服务的正常使用。
    </p>
    <p>
      （5）依照法律法规政策及相关管部门的要求，您需进行实名注册以使用服务，我们会在获取您的同意或由您主动提供的情况下收集您的个人身份信息包括姓名、身份证号码）.如您使用第三方账号关联本司服务，经过您授权后，我们可能从该第三方处获取您的的个人身份信息
      包据姓名、身份证件号码），收集上述信息是用于完成实名认证，您提供的实名身份息将与权威数接平台数据源进行比对，如二者比对信息一致，即成功通过认证。上述信息属于个人敏感信息，拒绝提供前述信息可能会导致您无法登录游戏及服务。
    </p>
    <p>
      （6）为满足用户身份真实核验，我们可能需要您提供真实身份信息（包括姓名、身份证件号码）
      、生物识别信息（静态或动态的面部特征）以完成身份认证。如果您不提供上述信息，我们将不能向您提供相关的功能及服务。
    </p>
    <p>为实现身份认证的目的，您同意并授权我们可能以自行或委托第三方有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对您的前述身份信息。</p>
    <p>请注意，您的身份信息、生物识别信息等属于个人敏感信息，请您谨慎提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</p>
    <p class="bold">3. 满足实名认证要求</p>
    <p>为满足相关法律法规政策及相关主管部门的要求，您需要进行实名认证以继续使用和享受本游戏软件及相关服务。我们会在获得您同意或您主动提供的情况下收集您的实名身份信息，该信息属于敏感信息，拒绝提供实名身份信息可能会导致您无法登陆本游戏软件及相关服务或在使用本游戏软件及相关服务过程中受到相应限制。</p>
    <!-- <p class="bold">4. 保障产品功能、服务及用户使用安全</p>
    <p>
      （1）游戏中的相关操作信息、游戏信息等信息（例如：您的登录状态、对战信息/状态、等级信息），并可向您本人或其他用户或好友展示该等信息。
    </p>
    <p>
      （2）您通过语音、视频与其他游戏玩家互动交流或您需要使用游戏内的情节互动功能时，我们会向您请求<span
        class="bold"
        >麦克风、摄像头</span
      >权限以保障该功能正常运行。您可以拒绝提供，且不影响您使用游戏其他功能。
    </p>
    <p>
      （3）为帮助我们更好地了解本游戏软件及相关服务的运行情况，以便确保运行与提供服务的安全，我们可能记录网络日志信息（例如：登录日志、物品日志、游戏对局信息），以便您能够在客户端查看您的游戏历史记录，同时用于游戏运营统计分析、客服投诉处理及其他游戏安全分析。
    </p>
    <p>
      （4）为了为您推荐您可能感兴趣的内容/商业广告等信息、丰富信息推荐维度，我们会向您请求开启<span
        class="bold"
        >地理位置权限。您可以拒绝开启，拒绝开启后，您看到的广告数量将保持不变，但广告的相关度会降低。精确地理位置信息属于个人敏感信息，拒绝提供该信息不影响您正常使用本游戏的其他功能。</span
      >
    </p>
    <p>
      （5）为了帮助我们改进游戏产品以及提供个性化服务，我们会收集您的应用信息、运行中的进程信息用于游戏运营数据统计分析，改进游戏产品功能，以便为您提供更好的产品服务。
    </p>
    <p>
      （6）为便您查询您的交易记录，同时尽最大程度保护您的虚拟物品安全，<span
        class="bold"
        >当您使用游戏产品的消费功能时（如有），我们会收集您的充值记录、消费记录等信息。</span
      >
    </p>
    <p>
      （7）我们可能会申请存储权限，用于存放广告下载的应用、读取图片/视频用于分享。
    </p>
    <p>
      （8）为保障您的游戏账号安全，营造公平、健康及安全的游戏环境，我们会收集您的硬件及操作系统信息、进程及游戏崩溃记录信息，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如用于检测盗版、扫描外挂、防止作弊等）。
    </p>
    <p>
      （9）为了预防恶意程序、确保运营质量及效率，我们会收集安装的应用信息、运行中的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
    </p>-->
    <p class="bold">4.使用产品及服务过程中</p>
    <p>（1）读取写入设备外部存储。我们需要获取读取写入设备外部存储的权限如您使用安卓设备，可能涉及的权限有“读取外部存储权限”、“读取写入SD卡内容权限“以及“修改/删除SD卡内容权限"，下同），获取上述权限及信息是用于设备中游戏产品的安装存储及读取游戏存档和文件（包括必要的游戏进程与缓存以及读取，写入/下载/保存/改图片或相册，文件，晶溃口志等信息），保您正常登录使用我们的服务，维护济戏基础功能的正常和稳定运行。如您拒绝提供前述权限将可能导致您无法使用我们的相关产品与服务。</p>
    <p>（2）游戏日志信息，包括登录日志、物品日志、商店购买日志、活动日志、公会日志、升级日志、聊天日志、游戏内交友记录、游戏对局信息及其他用户使用产品及服务过程中产生的游戏日志信息。我们收集上述信息是用于游戏运营统计分析、客服投诉处理、游戏反作弊分析及其他游戏体验优化分析，同时便于您在游戏客户端内查看您的游戏历史记录，如您拓绝提供前述权限将可能导致您无法使用我们的相关产品与服务。</p>
    <p>（3）互动信息。当您在游戏中通过文字，图片，语音及其他方式与其他玩家进行互动时，我们可能会收集并保存您发送的上述信息内容用于讨演色情、暴力、政治、辱骂、恶意广告等不当内容，以此净化游戏环境，维护健康的上网环境。如您拒绝提供前述权限将可能导致您无法使用我们的相关产品与服务。如您希望通过语音与其他用户互动或者拍摄、上传、保存图片，经过您授权同意后，会访问您的麦克风、摄像头（相机），相机读取写入设备外部存储权限。如您拒绝提供前述权限将可能导致您无法使用我们的相关产品与服务，但不影响您正常使用产品服务的其他功能（本隐私条款另有说明除外），同时您也可以随时在系统设置中关闭前述权限。</p>
    <p>
      （4）游戏应用信息、
      <span class="bold">运行中的进程信息、</span>设备信息（唯一设备识别码、设备序列号、设备制造商、设备名称，设备类型和版本，设备型号、设备样式，设备MAC地址）、网络身份标识信息P地址）、操作系统及进程信息、网络环境信息以及游戏崩溃记录，我们收集上述信息是用于验证账号信息、保喧您的游戏账号安全及广告数据监测及验证，同时也为了营造公平，健康及安全的游戏环境，打击破坏游戏公平环境或干扰，破坏游戏服务正常进行的行为（如用于检测盗版、扫描外挂、防止作整等），我们可能会使用您的IP地址识别设备连接至与联网时所在的位置，以用于实现特定活动内容及依照相关规定展示您的IP属地。特别提醒注意，在某些低版本的Android
      系统中，手机厂商可能会对权限申请进行打包处理，在该种情况下，我们获取设备信息的弹窗，可能会显示为是否允许拔打电话和管理通话，如您拒绝提供前述权限或信息将可能导致您无法使用我们的相关产品与服务。
    </p>
    <p>（5）交易信息，包括充值记录、消费记录及其他与交易相关的必要信息。您使用某些游戏内的消费功能时，我们会收集前述信息，以便您查询您为本易记录，同时尽最大程度保护您的虑拟物品安全，在交易出现争议时，为客服人员提供信息以支持争议诉求的处理。充值记录、消费记录属于个人敏感信息，收集上述信息为实现服务的消费功能所必需，否则将无法完成交易，如您拒绝提供前述权限或信息将可能导致您无法使用我们的相关产品与服务。</p>
    <p>（6）传感器信息，我们使用到的传感器为加速度计传感器，通过获取传感器的数据判新设备本身的方向变动，从而调整聊天界面在横显/坚屏下不同的显示布局。</p>

    <p class="bold">5.开展营销活动</p>
    <p>
      当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、
      <span class="bold">联系方式、银行账号</span>信息。
      <span class="bold">这些信息包括个人敏感信息</span>，是您收到转账或者礼品所必要的，如果您拒绝提供这些信息，我们将可能无法向您转账或发放礼品。
    </p>
    <p class="bold">（二）我们可能从第三方获得的您的个人信息</p>
    <p>1.当您主动使用第三方账号登录本游戏时，我们会收集经您授权的第三方账号的昵称、头像及其他公开信息。</p>
    <p>2.用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有您的部分信息。</p>
    <p class="bold">（三）收集、使用个人信息目的变更的处理</p>
    <p>请您了解，随着我们业务的发展，可能会对本游戏软件的功能和提供的服务有所调整变化。原则上，当新功能或服务与目前场景相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。</p>
    <p class="bold">（四）依法豁免征得同意收集和使用的个人信息</p>
    <p>请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：</p>
    <p>（1）与国家安全、国防安全直接相关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>（5）所收集的您的个人信息是您自行向社会公众公开的；</p>
    <p>（6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p>（7）根据您的要求签订或履行合同所必需的；</p>
    <p>（8）用于维护本游戏软件及相关服务的安全稳定运行所必需的，例如发现、处置本游戏软件及相关服务的故障；</p>
    <p>（9）为合法的新闻报道所必需的；</p>
    <p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
    <p>（11）法律法规规定的其他情形。</p>
    <p class="bold">特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
    <p class="bold">二、对 Cookie 和同类技术的使用</p>
    <p>
      Cookie
      和同类技术是互联网中的通用常用技术。当您使用本游戏软件及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个
      Cookie 或匿名标识符记录您的登录状态，方便您快捷登录。
    </p>
    <p>我们承诺，我们不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。</p>
    <p class="bold">三、我们如何存储个人信息</p>
    <p class="bold">（一）信息存储的地点</p>
    <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。</p>
    <p class="bold">（二）存储期限</p>
    <p>我们仅在为提供本游戏软件及服务之目的所必需的期间内保留您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
    <p class="bold">四、我们如何共享、转让、公开披露个人信息</p>
    <p class="bold">（一）个人信息的共享、转让</p>
    <p>
      我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，
      或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。
    </p>
    <p class="bold">1.我们可能会共享的个人信息</p>
    <p class="bold">（1）为实现相关功能或服务与关联方共享</p>
    <p>为保障您在我们及关联方提供的产品间所接受服务的一致性，并方便统一管理您的信息，我们会将您去标识化后的个人信息与这些关联方共享。</p>
    <p class="bold">（2）为实现程序化广告推送，我们可能会与广告合作伙伴共享去标识化或匿名化处理后的信息</p>
    <p>
      我们可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们不会分享用于识别您个人身份的信息，例如您的姓名、身份证号等，
      仅会向这些合作伙伴提供难以识别您个人身份的用户画像标签、去标识化或匿名化后的信息或位置信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率。
    </p>
    <p class="bold">（3）为实现特定功能而与业务合作伙伴共享</p>
    <p>当您在使用本游戏中由我们的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为您提供服务时我们会将实现业务所必需的信息与这些关联方、第三方共享，用于综合统计并通过算法做特征与偏好分析，形成间接人群画像，用以向您进行推荐、展示或推送您可能感兴趣的信息，或者推送更适合您的特定功能、服务或商业广告。</p>
    <!-- <p class="bold">（3）为实现特定功能而与业务合作伙伴共享</p>
    <p>
      当您在使用本游戏中由我们的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为您提供服务时我们会将实现业务所必需的信息与这些关联方、第三方共享，用于综合统计但不会对您进行个性化广告推送。
    </p>-->
    <p class="bold">（4）帮助您参加营销推广活动</p>
    <p>
      当您选择参加我们及我们的关联方或第三方举办的有关营销活动时，您可能需要提供姓名、通信地址、
      <span class="bold">联系方式、银行账号</span>等信息，
      <span class="bold">这些信息包含敏感个人信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。</span>经过您的同意，我们可能会将上述信息与关联方或第三方共享以保障您在联合活动中获得体验一致的服务，或委托第三方及时向您兑现奖励。
    </p>
    <p class="bold">2.对共享个人信息第三方主体的谨慎评估及责任约束</p>
    <p>（1）经您同意，我们只会与第三方共享实现目的所必要的信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的同意。</p>
    <p>
      （2）对我们与之共享您个人信息的第三方，我们将审慎评估该第三方数据使用共享信息的目的，对其安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。您可以点击
      <a class="agreement" @click="sdk()">《第三方SDK目录》</a>，了解我们主要接入的第三方SDK基本情况，各单项服务接入第三方SDK的情况可能有不同，如需了解具体单项服务接入第三方SDK的详细情况，请您前往对应的服务页面并查阅我们各单项服务的具体条款和相关页面。您也可以选择通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。
    </p>
    <p class="bold">3.收购、兼并、重组时个人信息的转让</p>
    <p>随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求个人信息的继受方继续保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。</p>
    <p class="bold">（二）个人信息的公开披露</p>
    <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</p>
    <p class="bold">1.根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</p>
    <p class="bold">2.对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息。</p>
    <p>（三）依法豁免征得同意共享、转让、公开披露的个人信息</p>
    <p>请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：</p>
    <p>（1）与国家安全、国防安全直接相关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p class="bold">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>（5）您自行向社会公众公开的个人信息；</p>
    <p>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
    <p class="bold">根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
    <p class="bold">五、我们如何保护个人信息安全</p>
    <p>（一）我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。</p>
    <p>（二）我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。</p>
    <p>（三）我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。</p>
    <p class="bold">
      （四）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
      因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
    </p>
    <p>（五）我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。</p>
    <p class="bold">（六）我们谨此特别提醒您，本隐私政策提供的个人信息保护措施仅适用于本游戏软件及相关服务。一旦您离开本游戏软件及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在本游戏软件及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于本游戏的链接或引导。</p>
    <p class="bold">六、您的权利</p>
    <p>
      我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、
      注销账号、投诉举报以及设置隐私功能等权利，以使您有能力保障您的隐私和安全。
    </p>
    <p class="bold">（一）访问、删除、更正您的个人信息</p>
    <p>在您使用本游戏服务期间，我们可能会视游戏产品具体情况为您提供相应的操作设置，以便您可以访问、删除、更正您的相关个人信息，您可参考相应游戏产品的具体指引进行操作。</p>
    <p class="bold">特别提示您注意，出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法自主修改注册时提交的某些初始注册信息。如您确有需要修改该类注册信息，请根据本隐私政策载明的联系方式联系我们。</p>
    <p class="bold">（二）改变您授权同意范围或撤销授权</p>
    <p>您可以在设备本身操作系统中关闭相关敏感权限改变同意范围或撤回您的授权。</p>
    <p class="bold">请您理解，特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</p>
    <p class="bold">（三）注销您的账号</p>
    <p>如果您不再希望使用我们的服务，您可以通过向我们发送邮件的方式申请注销账号。在您注销账号前，我们将验证您的个人身份、安全状态、设备信息等。请您知悉并理解，注销账号的行为是不可逆的行为，当您注销账号后，我们将删除有关您的相关信息，但法律法规另有规定的除外。</p>
    <p class="bold">（四）投诉举报</p>
    <p>您可按照我们公示的制度进行投诉或举报。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索（例如：认为我们收集您的个人信息违反法律规定或者双方约定），您可以及时与我们联系，我们核查后会及时反馈您的投诉与举报。</p>
    <p class="bold">（五）访问隐私政策</p>
    <p>您可以在注册登录页面，查看本隐私政策的全部内容或在游戏应用中查看本隐私政策全部内容。</p>
    <p class="bold">（六）停止运营向您告知</p>
    <p>如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
    <p class="bold">七、未成年人条款</p>
    <!-- <p class="bold">
      （一）若您是未满18周岁的未成年人，在使用本游戏软件及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。
    </p>
    <p class="bold">
      （二）我们会积极按照国家防沉迷政策要求，通过启用防沉迷系统保护未成年人的合法权益。我们会通过实名身份等信息校验判断相关账号的实名信息是否为未成年人，进而决定是否将此账号纳入到防沉迷体系中。另外，我们会收集您的登录时间、游戏时长等信息，通过从系统层面自动干预和限制未成年人游戏时间、启用强制下线功能等方式，引导未成年人合理游戏，并在疑似未成年人消费后尝试联系其监护人进行提醒、确认与处理，帮助未成年人健康上网。
    </p>
    <p class="bold">
      （三）我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、储存、共享、转让或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
    </p>
    <p class="bold">
      （四）若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
    </p>-->
    <p class="bold">我们建议：任何未成年人参加网上活动应事先取得父母或其他监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。</p>
    <p class="bold">
      我们鼓励监护人指导未成年人使用本公司服务。如果您是未满十四周岁的未成年人，请您通知您的监护人共同阅读并接受我们的
      <span class="agreement" @click="agreement">《 北京光耀世界网络技术有限公司儿童个人信息保护指引 》</span>并在您使用本公司服务、提交个人信息之前，寻求其同意和指导。。
    </p>
    <p class="bold">八、隐私政策的修订和通知</p>
    <p>（一）为给您提供更好的服务，本游戏软件及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。</p>
    <p>（二）本隐私政策更新后，我们会在本游戏发出更新版本，并在更新后的条款生效前通过游戏内公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。</p>
    <p class="bold">九、适用范围</p>
    <p>本隐私政策适用于由我们提供的本游戏软件及相关服务，不适用于有单独的隐私政策且未纳入本隐私政策的第三方产品或服务。</p>
    <p>本隐私权政策不适用于：</p>
    <p>1.其他第三方产品或服务，包括在本游戏软件及相关服务中链接到的其他产品或网站；</p>
    <p>2.为本游戏软件及相关服务进行广告宣传的其他第三方。</p>
    <p class="bold">您使用这些第三方服务（包括您向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请您仔细阅读第三方的条款。请您妥善保护自己的个人信息，仅在必要的情况下向第三方提供。</p>
    <p class="bold">请您了解，本隐私政策中所述的本游戏软件及相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的本游戏软件及相关服务为准。</p>
    <p class="bold">十、联系我们</p>
    <p class="bold">如果您对个人信息保护问题及本隐私政策内容有投诉、建议、意见、疑问，您可以通过拨打我们的网站联系方式与我们联系，我们核查并验证您的用户身份后会及时反馈您的投诉与举报。</p>
    <p>若您对本政策或与您个人信息相关的事宜有任何问题、意见或建议</p>
  </div>
</template>

<script>
export default {
  name: 'privacy1',
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.privacy {
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .agreement {
    color: royalblue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
